import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { loadConfig } from '../../env/config_util';

const config = loadConfig();

interface TagManagerScriptsProps {
  brand: string;
}

const TagManagerScripts = ({ brand }: TagManagerScriptsProps) => {
  const { 
    ftmId, 
    abTestScript, 
    abTestBaseUrl
  } = config.config_module[brand] || {};

  console.log('loading tag manager scripts for brand:', brand, ftmId, abTestScript);
  
  useEffect(() => {
    if (!ftmId) {
      console.warn('GTM ID is missing for brand:', brand);
      return;
    }

    try {
      // Inject GTM script dynamically
      const script = document.createElement('script');
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${ftmId}');
      `;
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    } catch (err) {
      console.error(err);
    }
  }, [ftmId, brand]);

  console.log('loadingScript', 'https://www.googletagmanager.com/ns.html?id='+ftmId);
  console.log('loadingScript', `${abTestBaseUrl}/${abTestScript}.js`);

  return (
    <>
      <Helmet>
        {ftmId && typeof ftmId === 'string' && (
          <noscript>{`
            <iframe src="https://www.googletagmanager.com/ns.html?id=${ftmId}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `}</noscript>
        )}

        {/* A/B Testing Script */}
        {abTestScript && typeof abTestScript === 'string' && (
          <script type="text/javascript" src={`${abTestBaseUrl}/${abTestScript}.js`} />
        )}
      </Helmet>
    </>
  );
};

export default TagManagerScripts;
