import { STRIPE_ELEMENTS } from '../constants/providerNames';

function getRecurlyValue(field: any) {
  if (field && field.$ && field.$.nil === 'nil') {
    return '';
  }
  return field;
}

function isDate(date: any) {
  if (date && date === 'Invalid Date') {
    return false;
  }
  return Object.prototype.toString.call(date) === '[object Date]';
}

function isBetweenDate(fromDate: any, toDate: any, checkDate: any) {
  if (!fromDate || !isDate(fromDate)) {
    return false;
  }
  if (!toDate || !isDate(fromDate)) {
    return false;
  }
  if (!checkDate || !isDate(fromDate)) {
    return false;
  }

  if (checkDate.getTime() > fromDate.getTime() && checkDate.getTime() < toDate.getTime()) {
    return true;
  }
  return false;
}

/*
 * description - parse a given url to extract the email query param. Needed to avoid
 * encoding which isn't possible with the natural dependency (query-string node module).
 * Original use case is verifyEmail and resetPassword need to get email from the url and send
 * the un-encoded email to the back end
 *
 * @param {string} str
 *
 * @return {string} email
 */
function parseLocationForEmail(str: any) {
  if (!str) {
    return '';
  }
  const locationParts: any = str.split('email=');

  // in case the email query param doesn't exist
  if (!(locationParts.length > 1)) {
    return '';
  }

  // in case there are additional query params after &email=
  const email = locationParts[1] && locationParts[1].includes('&') ? locationParts[1].split('&')[0] : locationParts[1];

  return email;
}

/**
 * Simple email validator regex
 * @param email - string of email address, ex: 'testuser@pepper.me'
 * @return boolean - true or false, depending on if email is valid
 */
function isValidEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/**
 * Determines if the billing provider is V3 (Stripe Elements)
 * @param provider - string of billing provider
 * @return boolean - true if provider is stripeElements, false otherwise
 */
function isV3Provider(provider: string): boolean {
  return provider === STRIPE_ELEMENTS;
}

/**
 * Normalizes a billing info field value to a string
 * @param field - The field value which could be a string or an object
 * @return string - The normalized string value
 */
function normalizeBillingField(field: any): string {
  // If it's a simple string/number, return as is
  if (typeof field === 'string' || typeof field === 'number') {
    return String(field);
  }

  // Handle Recurly-style objects with '_' property
  if (field && typeof field === 'object' && '_' in field) {
    return String(field._);
  }

  // Handle objects with $ property indicating null
  if (field && typeof field === 'object' && '$' in field && field.$.nil === 'nil') {
    return '';
  }

  // For any other object or invalid value, return empty string
  return '';
}

/**
 * Normalizes an entire billing info object to have string values
 * @param billingInfo - The billing info object
 * @return object - A new object with all values normalized to strings
 */
function normalizeBillingInfo(billingInfo: any): any {
  if (!billingInfo || typeof billingInfo !== 'object') {
    return {};
  }

  return Object.keys(billingInfo).reduce((normalized: any, key: string) => {
    normalized[key] = normalizeBillingField(billingInfo[key]);
    return normalized;
  }, {});
}

export {
  getRecurlyValue,
  isBetweenDate,
  isDate,
  isValidEmail,
  parseLocationForEmail,
  isV3Provider,
  normalizeBillingField,
  normalizeBillingInfo,
};
