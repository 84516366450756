export const config_module = {
  momentum: {
    base_url: 'https://dev.api.pepperos.io',
    oauth_url: 'https://oauth2.dev-multi.pepperos.io',
    context: 'dev',
    recurlyUrl: 'https://js.recurly.com/v4/recurly.js',
    recurlyPublicKey: 'ewr1-ZeSnXhWp5gJqz0nHhEuX7m',
    configToken: 'cGVwcGVyTWV0YURhdGE6TGQmaW1wQW9UcnFp',
  },
  pepper: {
    base_url: 'https://dev.api.pepperos.io',
    oauth_url: 'https://oauth2.dev-multi.pepperos.io',
    context: 'dev',
    recurlyUrl: 'https://js.recurly.com/v4/recurly.js',
    recurlyPublicKey: 'ewr1-BSkoONxL11oD53d3N1TP2z',
    configToken: '077b69d04c5d423d8f048a1798b8474a',
    analytics: {
      provider: 'clevertap',
      config: {
        accountId: 'TEST-K5Z-56R-7R5Z',
      },
    },
    appStoreUrl: {
      android: 'https://play.google.com/store/apps/details?id=com.pepper.too&hl=en',
    },
  },
  otis: {
    base_url: 'https://dev.api.pepperos.io',
    oauth_url: 'https://oauth2.dev-multi.pepperos.io',
    context: 'dev',
    recurlyUrl: 'https://js.recurly.com/v4/recurly.js',
    recurlyPublicKey: 'ewr1-txfmldTNvCC9C5TZ4oHa6Z',
    configToken: '077b69d04c5d423d8f048a1798b8474a',
  },
  visi: {
    base_url: 'https://dev.api.pepperos.io',
    oauth_url: 'https://oauth2.dev-multi.pepperos.io',
    context: 'dev',
    recurlyUrl: 'https://js.recurly.com/v4/recurly.js',
    recurlyPublicKey: 'ewr1-xGsOYfDwJh1rKpWJYYxPbA',
    configToken: 'cGVwcGVyTWV0YURhdGE6cjcmIUUrIW1uPGdMcnEoTA==',
  },
  geeni: {
    base_url: 'https://dev.api.pepperos.io',
    oauth_url: 'https://oauth2.dev-multi.pepperos.io',
    context: 'dev',
    recurlyUrl: 'https://js.recurly.com/v4/recurly.js',
    recurlyPublicKey: 'ewr1-6UN0NFBn9RXWj3ZqW0lFu7',
    ftmId: 'GTM-N5KLK68N',
    abTestScript: '5BXrxOUpYv^1',
    abTestBaseUrl: 'https://crsoptimizer.conversionrate.store/comp',
    recurlyGooglePay: {
      // These should correspond to values in API docs https://recurly.com/developers/reference/recurly-js/#google-pay-
      merchantId: '9743-2562-0788',
      environment: 'TEST',
    },
    stripePublicKey:
      'pk_test_40DpPkZhqK69boNdC3ygyBgsCMGIzMq9rbRmeqBbD7ELUduU6gW4NcmKhvinbztWdiNVFfUfknl2OsCRDkFfVe7s7003wu2I6Mq',
    configToken: 'cGVwcGVyTWV0YURhdGE6Ni1yMywtY0VlaDtSSFdYJQ==',
    stripeAccountId: 'acct_1LkZQlIxsqGHDLj3',
    analytics: {
      provider: 'clevertap',
      config: {
        accountId: 'TEST-Z4R-8K7-756Z',
      },
    },
    social: {
      google: {
        clientId: '1060205334349-ptsrvmpukp13ku1834igtbs7morbcnjf.apps.googleusercontent.com',
      },
      apple: {
        redirectUriBase: 'https://dev.account.merkurysmart.com',
        lrUri: 'https://geeni-dev.hub.loginradius.com/RequestHandler.aspx',
        lrApiKey: '373ede2e-80a7-4777-ba18-86cdc3d4f87b',
      },
    },
    appStoreUrl: {
      ios: 'https://apps.apple.com/us/app/geeni/id1177386276',
      android: 'https://play.google.com/store/apps/details?id=com.merkuryinnovations.geeni&hl=en',
    },
  },
  notion: {
    base_url: 'https://dev.api.pepperos.io',
    oauth_url: 'https://oauth2.dev-multi.pepperos.io',
    context: 'dev',
    configToken: '077b69d04c5d423d8f048a1798b8474a',
    analytics: {
      provider: 'clevertap',
      config: {
        accountId: 'TEST-9WR-W9Z-456Z',
      },
    },
  },
  nightowl: {
    base_url: 'https://dev.api.pepperos.io',
    oauth_url: 'https://oauth2.dev-multi.pepperos.io',
    context: 'dev',
    recurlyUrl: 'https://js.recurly.com/v4/recurly.js',
    recurlyPublicKey: 'ewr1-uRacqSve3l448lnbIsHSuM',
    configToken: 'cGVwcGVyTWV0YURhdGE6eHZ2NG5KNjUlITYx',
    analytics: {
      provider: 'clevertap',
      config: {
        accountId: 'TEST-798-4WK-R96Z',
      },
    },
  },
};
