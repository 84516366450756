export const STORE_BILLING_INFO_STRIPE_ATTEMPT = 'STORE_BILLING_INFO_STRIPE_ATTEMPT';
export const STORE_BILLING_INFO_STRIPE_SUCCESS = 'STORE_BILLING_INFO_STRIPE_SUCCESS';
export const STORE_BILLING_INFO_STRIPE_FAILURE = 'STORE_BILLING_INFO_STRIPE_FAILURE';

export const VERIFY_BILLING_INFO_STRIPE_ATTEMPT = 'VERIFY_BILLING_INFO_STRIPE_ATTEMPT';
export const VERIFY_BILLING_INFO_STRIPE_SUCCESS = 'VERIFY_BILLING_INFO_STRIPE_SUCCESS';
export const VERIFY_BILLING_INFO_STRIPE_FAILURE = 'VERIFY_BILLING_INFO_STRIPE_FAILURE';

export const FETCH_CLIENT_SECRET_ATTEMPT = 'FETCH_CLIENT_SECRET_ATTEMPT';
export const FETCH_CLIENT_SECRET_SUCCESS = 'FETCH_CLIENT_SECRET_SUCCESS';
export const FETCH_CLIENT_SECRET_FAILURE = 'FETCH_CLIENT_SECRET_FAILURE';

export const FETCH_BILLING_INFO_STRIPE_ATTEMPT = 'FETCH_BILLING_INFO_STRIPE_ATTEMPT';
export const FETCH_BILLING_INFO_STRIPE_SUCCESS = 'FETCH_BILLING_INFO_STRIPE_SUCCESS';
export const FETCH_BILLING_INFO_STRIPE_FAILURE = 'FETCH_BILLING_INFO_STRIPE_FAILURE';

export const CLEAR_STATE = 'CLEAR_STATE';
