import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getBillingInfoSuccess,
  getBillingInfoFailure,
  createBillingInfoUpdateDelegateSuccess,
  createBillingInfoUpdateDelegateFailure,
} from './actions';
import { GET_PAYMENT_INFORMATION_ATTEMPT, CREATE_PAYMENT_INFORMATION_UPDATE_DELEGATE_ATTEMPT } from './actionTypes';
import { getDataFromService, postDataToService } from '../../services/apiGatewayClient';
import { SelectState, TypedIterableIterator } from '../../modules/helpers';
import { getBillingProviderSelector } from '../BrandProvider/selectors';
import { isV3Provider } from '../../utils/pepperUtils';

export function* getBillingInfo(): TypedIterableIterator<any> {
  try {
    const billingProvider = yield SelectState<any>(getBillingProviderSelector);

    const path = isV3Provider(billingProvider) ? '/billingInfoV3' : '/billingInfo';

    const billingInfo = yield call(getDataFromService, path, 'subscriptions');
    yield put(getBillingInfoSuccess(billingInfo));
  } catch (err) {
    yield put(getBillingInfoFailure(err));
  }
}

export function* billingInfoUpdateDelegate(): TypedIterableIterator<any> {
  try {
    const billingInfoUpdateDelegateUrlResponse = yield call(
      postDataToService,
      '/billingInfoUpdateDelegate',
      { returnUrl: window.location.href },
      'subscriptions',
    );
    yield put(createBillingInfoUpdateDelegateSuccess(billingInfoUpdateDelegateUrlResponse.checkoutUrl));
  } catch (err) {
    yield put(createBillingInfoUpdateDelegateFailure(err));
  }
}

function* paymentInformationSaga() {
  yield all([takeLatest(GET_PAYMENT_INFORMATION_ATTEMPT, getBillingInfo)]);
  yield all([takeLatest(CREATE_PAYMENT_INFORMATION_UPDATE_DELEGATE_ATTEMPT, billingInfoUpdateDelegate)]);
}

export default paymentInformationSaga;
