import { SubscriptionInfo, SubscriptionTypes } from './types';

import {
  GET_SUBSCRIPTION_PLANS_ATTEMPT,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
  GET_SUBSCRIPTION_PLANS_FAILURE,
  CHECK_MIGRATION_REQUIRED,
  CHECK_MIGRATION_COMPLETE,
  MIGRATION_ATTEMPT,
  MIGRATION_FAILURE,
  MIGRATION_COMPLETE,
  CLEAR_MIGRATION_STATE,
  CLEAR_STATE,
  GET_DEVICE_MODELS_ATTEMPT,
  GET_DEVICE_MODELS_FAILURE,
  GET_DEVICE_MODELS_SUCCESS,
  STOP_DEVICE_MODELS_FETCH,
} from './actionTypes';

import { SSO_SUBSCRIPTIONS_DEVICES_SUCCESS } from '../SsoRedirect/actionTypes';
import { SUBSCRIPTION_ATTEMPT } from '../OrderSummary/actionTypes';

export const initialState: SubscriptionInfo = {
  subscriptionPlans: [],
  error: null,
  attempting: 'idle',
  isMigrationRequired: null,
  checkingMigration: false,
  migratedSubscription: null,
  wasMigrated: null,
  migrationAttempting: false,
  modelsAttempting: false,
  migrationError: null,
  migrationComplete: false,
  deviceModels: [],
  lastDeviceModelsFetch: null,
};

export const SubscriptionListReducer = (state = initialState, action: SubscriptionTypes): SubscriptionInfo => {
  switch (action.type) {
    case GET_SUBSCRIPTION_PLANS_ATTEMPT:
      return {
        ...state,
        attempting: 'pending',
      };
    case GET_SUBSCRIPTION_PLANS_FAILURE:
      return {
        ...state,
        error: action.error,
        attempting: 'rejected',
      };
    case SUBSCRIPTION_ATTEMPT:
      return {
        ...state,
        attempting: 'idle', // When subscribing again, the state here is stale
      };
    case GET_SUBSCRIPTION_PLANS_SUCCESS:
    case SSO_SUBSCRIPTIONS_DEVICES_SUCCESS:
      return {
        ...state,
        error: null,
        attempting: 'fulfilled',
        subscriptionPlans: action.subscriptionPlans,
      };
    case GET_DEVICE_MODELS_ATTEMPT:
      return {
        ...state,
        modelsAttempting: true,
      };
    case GET_DEVICE_MODELS_FAILURE:
      return {
        ...state,
        modelsAttempting: false,
        error: action.error,
      };
    case GET_DEVICE_MODELS_SUCCESS:
      return {
        ...state,
        error: null,
        modelsAttempting: false,
        deviceModels: action.deviceModels,
        lastDeviceModelsFetch: Date.now(),
      };
    case STOP_DEVICE_MODELS_FETCH:
      return {
        ...state,
        modelsAttempting: false,
      };
    case CHECK_MIGRATION_REQUIRED:
      return {
        ...state,
        isMigrationRequired: null,
        checkingMigration: true,
      };
    case CHECK_MIGRATION_COMPLETE:
      return {
        ...state,
        checkingMigration: false,
        isMigrationRequired: action.isMigrationRequired,
      };
    case MIGRATION_ATTEMPT:
      return {
        ...state,
        checkingMigration: false,
        wasMigrated: null,
        migratedSubscription: null,
        migrationAttempting: true,
      };
    case MIGRATION_FAILURE:
      return {
        ...state,
        checkingMigration: false,
        wasMigrated: null,
        migratedSubscription: null,
        migrationAttempting: false,
        migrationComplete: false,
        migrationError: action.error,
      };
    case MIGRATION_COMPLETE:
      return {
        ...state,
        checkingMigration: false,
        migrationAttempting: false,
        migrationError: null,
        wasMigrated: action.migrated,
        migratedSubscription: action.subsData,
        migrationComplete: true,
      };
    case CLEAR_MIGRATION_STATE:
      return {
        ...state,
        isMigrationRequired: null,
        checkingMigration: false,
        migratedSubscription: null,
        wasMigrated: null,
        migrationAttempting: false,
        migrationError: null,
        migrationComplete: false,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
