import React, { ReactElement, useEffect, useState } from 'react';

//third party libs
import { Switch, Route } from 'react-router-dom';

//containers
import { PaymentInformation } from '../PaymentInformation';
import AccountHistory from '../AccountHistory';
import FlowLayout from '../Layouts/FlowLayout';
import MainLayout from '../Layouts/MainLayout';
import LoginPage from '../LoginPage';
import SubscriptionsList from '../SubscriptionsList';
import PlanListPage from '../PlanListPage';
import ManageDevices from '../ManageDevices';
import OrderSummary from '../OrderSummary';
import CheckoutSuccess from '../CheckoutSuccess';
import MultiFactorAuthentication from '../LoginPage/multiFactorAuthentication';
// import GenericErrorPage from "../GenericErrorPage";
import CancelPlan from '../CancelPlan';
import NotFoundPage from '../NotFoundPage';
import ErrorStatusPage from '../ErrorStatusPage';
import EmptyLayout from '../Layouts/EmptyLayout';
import PaymentDetails from '../PaymentDetails';
import SsoRedirect from '../SsoRedirect';
import VerifyEmail from '../VerifyEmail';
import ResetPassword from '../ResetPassword';
import ForgotPassword from '../ForgotPassword';
import DeleteAccountModel from '../DeleteAccountModel';
import UnifiedCheckout from '../UnifiedCheckout';
import StripeElementsSetup from '../StripeElementsSetup';

//redux
import { getBrandNameSelector } from '../BrandProvider/selectors';
import { useSelector } from 'react-redux';
import { brandNames } from '../../constants/brandNames';
import { getIsPendingDeleteSelector, getProfileSelector } from '../LoginPage/selectors';

import history from '../../history';
import { identifyUser, initAnalytics } from '../../utils/analytics/analyticsLogger';
import { GuardedRoute } from './GuardedRoute';
import IsolatedSsoRedirect from '../SsoIsolated';
import { AccountShare } from '../AccountShare';
import TagManagerScripts from '../../components/TagManagerScripts';

export default function App(): ReactElement {
  const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false);
  const brand = useSelector(getBrandNameSelector);
  const isPendingDelete = useSelector(getIsPendingDeleteSelector);
  const profile = useSelector(getProfileSelector);

  //
  //  Add check for URL parameter
  //      ?disableGTM=true
  const gtmParam = new URLSearchParams(window.location.search).get('disableGTM');
  const disableTagManager = gtmParam === null || gtmParam === 'true';

  useEffect(() => {
    if (isPendingDelete && isPendingDelete === 'pending_delete') {
      history.push('/pending-delete');
    }
  }, [isPendingDelete]);

  // Initialize the analytics package so that analytics events can be reported.
  useEffect(() => {
    if (!isAnalyticsInitialized) {
      initAnalytics(brand);
      setIsAnalyticsInitialized(true);
    }
  }, [brand, isAnalyticsInitialized]);

  useEffect(() => {
    if (isAnalyticsInitialized && profile) {
      identifyUser(profile);
    }
  }, [isAnalyticsInitialized, profile]);

  return (
    <>
      {brand === 'geeni' && !disableTagManager && <TagManagerScripts brand={brand} />}
      <Switch>
        <Route exact path="/" render={() => <LoginPage />} />
        <Route path="/sso-redirect" render={() => <SsoRedirect />} />
        <Route path="/isolated-sso-redirect" render={() => <IsolatedSsoRedirect />} />
        <Route
          path="/verify-email"
          render={() => (
            <EmptyLayout layoutClasses="white">
              <VerifyEmail />
            </EmptyLayout>
          )}
        />
        <Route
          path="/reset-password"
          render={() => (
            <EmptyLayout layoutClasses="white">
              <ResetPassword />
            </EmptyLayout>
          )}
        />
        <Route
          path="/forgot-password"
          render={() => (
            <EmptyLayout>
              <ForgotPassword />
            </EmptyLayout>
          )}
        />
        <Route
          path="/account-history"
          render={() => (
            <GuardedRoute resume>
              <MainLayout>
                <AccountHistory />
              </MainLayout>
            </GuardedRoute>
          )}
        />
        <Route
          path="/subscriptions"
          render={() => (
            <GuardedRoute resume>
              <MainLayout>
                <SubscriptionsList />
              </MainLayout>
            </GuardedRoute>
          )}
        />
        <Route
          path="/plans"
          render={() => (
            <FlowLayout pageName={'Plans'}>
              <PlanListPage />
            </FlowLayout>
          )}
        />
        <Route
          path="/share"
          render={() => (
            <FlowLayout pageName={'Share'}>
              <AccountShare />
            </FlowLayout>
          )}
        />
        <Route
          path="/billing"
          render={() => (
            <GuardedRoute resume>
              <FlowLayout pageName={'Payment Details'}>
                <PaymentDetails />
              </FlowLayout>
            </GuardedRoute>
          )}
        />
        <Route
          path="/manage-devices"
          render={() => (
            <GuardedRoute resume>
              <FlowLayout pageName={'Manage Devices'}>
                <ManageDevices />
              </FlowLayout>
            </GuardedRoute>
          )}
        />
        <Route
          path="/order-summary"
          render={() => (
            <GuardedRoute>
              <FlowLayout pageName={'Order Summary'}>
                <OrderSummary />
              </FlowLayout>
            </GuardedRoute>
          )}
        />
        <Route
          path="/checkout-success"
          render={() => (
            <GuardedRoute>
              <FlowLayout pageName="Checkout Success">
                <CheckoutSuccess />
              </FlowLayout>
            </GuardedRoute>
          )}
        />
        <Route
          path="/checkout"
          render={() => (
            <GuardedRoute resume>
              <FlowLayout pageName="Checkout">
                <UnifiedCheckout />
              </FlowLayout>
            </GuardedRoute>
          )}
        />
        <Route path="/login" render={() => <LoginPage />} />
        <Route path="/mfaLogin" render={() => <MultiFactorAuthentication />} />
        <Route
          path="/subscription"
          render={() => (
            <EmptyLayout>
              <LoginPage />
            </EmptyLayout>
          )}
        />
        <Route
          path="/payment"
          render={() => (
            <GuardedRoute resume>
              <MainLayout>
                <PaymentInformation />
              </MainLayout>
            </GuardedRoute>
          )}
        />
        <Route
          path="/error"
          render={() => (
            <EmptyLayout>
              <ErrorStatusPage noSupportButton={true} />
            </EmptyLayout>
          )}
        />
        <Route
          path="/pending-delete"
          render={() => (
            <GuardedRoute>
              <EmptyLayout>
                <DeleteAccountModel />
              </EmptyLayout>
            </GuardedRoute>
          )}
        />
        {brand === brandNames.otis ||
        brand === brandNames.visi ||
        brand === brandNames.notion ||
        brand === brandNames.geeni ? (
          <Route
            path="/cancel-plan"
            render={() => (
              <GuardedRoute resume>
                <FlowLayout pageName={'Cancel Plan'}>
                  <CancelPlan />
                </FlowLayout>{' '}
              </GuardedRoute>
            )}
          />
        ) : null}
        <Route
          path="/error-status"
          render={() => (
            <EmptyLayout>
              <ErrorStatusPage />
            </EmptyLayout>
          )}
        />
        <Route
          path="/oauth"
          render={() => (
            <EmptyLayout>
              <LoginPage />
            </EmptyLayout>
          )}
        />
        <Route
          path="/setup-stripe-elements"
          render={() => (
            <EmptyLayout>
              <StripeElementsSetup />
            </EmptyLayout>
          )}
        />
        <Route
          path=""
          render={() => (
            <MainLayout>
              <NotFoundPage />
            </MainLayout>
          )}
        />
      </Switch>
    </>
  );
}
