import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cardWrapper: {
    [theme.breakpoints.down('sm')]: {
      margin: '0px 0',
    },
  },
  titleText: {
    paddingTop: 15,
    paddingLeft: 34,
    [theme.breakpoints.down('sm')]: {
      padding: '24px 4px 0',
      fontSize: '1.25rem',
    },
  },
  stripeElement: {
    padding: '0px 32px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      paddingBottom: 100,
    },
  },
}));
