import * as types from './actionTypes';

const initialState = {
  loading: false,
  loadingClientSecret: false,
  error: null,
  billingInfo: null,
  clientSecret: null,
  loadingBillingInfo: false,
  billingInfoFromRecurly: null,
};

export const stripeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.STORE_BILLING_INFO_STRIPE_ATTEMPT:
    case types.VERIFY_BILLING_INFO_STRIPE_ATTEMPT:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.STORE_BILLING_INFO_STRIPE_SUCCESS:
      return {
        ...state,
        billingInfoFromRecurly: action.payload,
        loading: false,
      };

    case types.VERIFY_BILLING_INFO_STRIPE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.STORE_BILLING_INFO_STRIPE_FAILURE:
    case types.VERIFY_BILLING_INFO_STRIPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.FETCH_CLIENT_SECRET_ATTEMPT:
      return {
        ...state,
        loadingClientSecret: true,
        error: null,
      };

    case types.FETCH_CLIENT_SECRET_SUCCESS:
      return {
        ...state,
        loadingClientSecret: false,
        clientSecret: action.payload,
      };

    case types.FETCH_CLIENT_SECRET_FAILURE:
      return {
        ...state,
        loadingClientSecret: false,
        error: action.error,
      };

    case types.FETCH_BILLING_INFO_STRIPE_ATTEMPT:
      return {
        ...state,
        loadingBillingInfo: true,
        error: null,
      };

    case types.FETCH_BILLING_INFO_STRIPE_SUCCESS:
      return {
        ...state,
        loadingBillingInfo: false,
        billingInfo: action.payload,
      };

    case types.FETCH_BILLING_INFO_STRIPE_FAILURE:
      return {
        ...state,
        loadingBillingInfo: false,
        error: action.error,
      };

    case types.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
