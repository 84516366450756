import { combineReducers } from 'redux';

import { BrandInfoReducer } from '../containers/BrandProvider/reducer';
import { AuthenticationReducer } from '../containers/LoginPage/reducer';
import { SubscriptionListReducer } from '../containers/SubscriptionsList/reducer';
import { PlansListReducer } from '../containers/PlanListPage/reducer';
import { BillingInfoReducer } from '../containers/BillingInformationView/reducer';
import { PaymentInfoReducer } from '../containers/PaymentInformation/reducer';
import { DeviceListReducer } from '../containers/DeviceList/reducer';
import { AccountHistoryReducer } from '../containers/AccountHistory/reducer';
import { ManageDevicesReducer } from '../containers/ManageDevices/reducer';
import { SsoRedirectReducer } from '../containers/SsoRedirect/reducer';
import { OrderSummaryReducer } from '../containers/OrderSummary/reducer';
import { PromoCodeReducer } from '../containers/PromoCode/reducer';
import { PathReducer } from '../containers/PathName/reducer';
import { CancelPlanReducer } from '../containers/CancelPlan/reducer';
import { VerifyEmailReducer } from '../containers/VerifyEmail/reducer';
import { ResetPasswordReducer } from '../containers/ResetPassword/reducer';
import { LanguageProviderReducer } from '../components/i18n/reducer';
import { CancelDeleteAccountReducer } from '../containers/DeleteAccountModel/reducer';
import { CheckoutSessionReducer } from './CheckoutSession/reducer';
import { CheckoutSuccessReducer } from '../containers/CheckoutSuccess/reducer';
import { UnifiedCheckoutReducer } from '../containers/UnifiedCheckout/reducer';
import { IsolatedSsoRedirectReducer } from '../containers/SsoIsolated/reducer';
import { DeleteAccountReducer } from '../containers/DeleteAccountModalRequest/reducer';
import { stripeReducer } from '../containers/BillingInformationViewStripe/reducer';

const rootReducer = combineReducers({
  brand: BrandInfoReducer,
  authentication: AuthenticationReducer,
  subscriptionList: SubscriptionListReducer,
  planList: PlansListReducer,
  billingInfo: BillingInfoReducer,
  paymentInfo: PaymentInfoReducer,
  deviceList: DeviceListReducer,
  accountHistory: AccountHistoryReducer,
  manageDevices: ManageDevicesReducer,
  ssoRedirect: SsoRedirectReducer,
  isolatedSsoRedirect: IsolatedSsoRedirectReducer,
  orderSummary: OrderSummaryReducer,
  promoCode: PromoCodeReducer,
  pathReducer: PathReducer,
  cancelPlan: CancelPlanReducer,
  verifyEmail: VerifyEmailReducer,
  resetPassword: ResetPasswordReducer,
  languageProvider: LanguageProviderReducer,
  cancelDelete: CancelDeleteAccountReducer,
  checkoutSession: CheckoutSessionReducer,
  checkoutSuccess: CheckoutSuccessReducer,
  unifiedCheckout: UnifiedCheckoutReducer,
  deleteAccount: DeleteAccountReducer,
  stripe: stripeReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
