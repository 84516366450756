import { defineMessages } from 'react-intl';

export default defineMessages({
  billingTitle: {
    id: 'billing.information.billing.title',
    defaultMessage: 'Billing Information',
  },
  creditCardTitle: {
    id: 'billing.information.credit.card.title',
    defaultMessage: 'Credit Card Information',
  },
  streetAddressLabel: {
    id: 'billing.information.street.address.label',
    defaultMessage: 'Street Address',
  },
  aptLabel: {
    id: 'billing.information.apt.label',
    defaultMessage: 'Apt/Suite (Optional)',
  },
  cityLabel: {
    id: 'billing.information.city.label',
    defaultMessage: 'City',
  },
  stateLabel: {
    id: 'billing.information.state.label',
    defaultMessage: 'State',
  },
  stateInputLabel: {
    id: 'billing.information.state.input.label',
    defaultMessage: 'State/Province/Region',
  },
  zipLabel: {
    id: 'billing.information.zip.label',
    defaultMessage: 'ZIP Code',
  },
  postalCodeLabel: {
    id: 'billing.information.postal.code.label',
    defaultMessage: 'Postal Code',
  },
  countryLabel: {
    id: 'billing.information.country.label',
    defaultMessage: 'Country',
  },
  firstNameLabel: {
    id: 'billing.information.first.name.label',
    defaultMessage: 'First Name',
  },
  lastNameLabel: {
    id: 'billing.information.last.name.label',
    defaultMessage: 'Last Name',
  },
  cardLabel: {
    id: 'billing.information.card.label',
    defaultMessage: 'Card Information',
  },
});
