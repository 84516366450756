import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BillingInformationViewStripe from '../BillingInformationViewStripe';
import PaymentDetailsActions from './PaymentDetailsActions';
import { IBillingStripeUserInfo } from '../BillingInformationViewStripe/types';
import { storeBillingInfoStripeAttempt, verifyBillingInfoStripeAttempt } from '../BillingInformationViewStripe/actions';

interface Props {
  brand: string;
  lastPath: string;
}

const PaymentDetailsStripeElements = ({ brand, lastPath }: Props) => {
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errorMapping, setErrorMapping] = useState<string | null>(null);
  const formRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setSubmitDisabled(true);
      setErrorMapping(null);
      setIsLoading(true);
      // Trigger the Stripe form submission
      if (formRef.current) {
        await formRef.current.submit();
      }
    } catch (error: any) {
      setErrorMapping(error.message);
      setSubmitDisabled(false);
      setIsLoading(false);
    }
  };

  const onStripeComplete = (billingInfo: any) => {
    try {
      if (lastPath === '/payment') {
        dispatch(
          storeBillingInfoStripeAttempt({
            selectedBillingInfo: billingInfo,
          }),
        );
      } else {
        dispatch(
          verifyBillingInfoStripeAttempt({
            selectedBillingInfo: billingInfo,
          }),
        );
      }
    } catch (error: any) {
      setSubmitDisabled(false);
      setErrorMapping(error.message);
    } finally {
      setSubmitDisabled(false);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <BillingInformationViewStripe
        userInfo={{} as IBillingStripeUserInfo}
        showPromoSection={lastPath !== '/payment'}
        errorMessage={errorMapping}
        ref={formRef}
        onComplete={onStripeComplete}
      />
      <PaymentDetailsActions
        brand={brand}
        lastPath={lastPath}
        handleSubmit={handleSubmit}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PaymentDetailsStripeElements;
