import React, { forwardRef, useState, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import CircularProgress from '@material-ui/core/CircularProgress';

// Material UI
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

// Components
import CheckoutForm from './CheckoutForm';

// Types and Styles
import { useStyles } from './styles';
import messages from './messages';

// Selectors
import { getBrandNameSelector } from '../BrandProvider/selectors';
import { getStripeLoadingSelector, getStripeLoadingClientSecretSelector } from './selectors';

import { getStripeAccountId, getStripePublicKey } from '../../env/config_util';

interface Props {
  userInfo: any;
  showPromoSection?: boolean;
  onComplete: (billingInfo: any) => void;
  errorMessage?: string | null;
}

const BillingInformationViewStripe = forwardRef(
  ({ userInfo, showPromoSection, onComplete, errorMessage }: Props, ref) => {
    const brand = useSelector(getBrandNameSelector);
    const classes = useStyles();
    const loading = useSelector(getStripeLoadingSelector);
    const [isStripeLoading, setIsStripeLoading] = useState(true);

    const stripePublicKey = getStripePublicKey(brand);
    const stripeAccountId = getStripeAccountId(brand);
    const loadingClientSecret = useSelector(getStripeLoadingClientSecretSelector);

    // Memoize stripePromise
    const stripePromise = useMemo(
      () =>
        loadStripe(stripePublicKey, {
          stripeAccount: stripeAccountId,
        }),
      [stripePublicKey, stripeAccountId],
    );

    useEffect(() => {
      stripePromise
        .then(() => {
          setIsStripeLoading(false);
        })
        .catch(error => {
          console.error('Failed to load Stripe:', error);
          setIsStripeLoading(false);
        });
    }, [stripePromise]);

    const appearance: Appearance = {
      theme: 'stripe',
      variables: {
        colorPrimary: '#37766f', // $merkury-green
      },
      rules: {
        '.Input': {
          backgroundColor: '#f8f9fa',
          padding: '12px',
        },
        '.Input:focus': {
          boxShadow: '0 0 0 2px rgba(55, 118, 111, 0.2)', // $merkury-green with opacity
          borderColor: '#37766f', // $merkury-green
        },
        '.Label': {
          fontWeight: '500',
        },
      },
    };

    const options: StripeElementsOptions = {
      appearance,
      mode: 'setup',
      currency: 'usd',
      setupFutureUsage: 'off_session',
    };

    if (loading || isStripeLoading || loadingClientSecret) {
      return (
        <div style={{ height: '75vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={55} className={`${brand}-button-progress`} />
        </div>
      );
    }

    return (
      <Container maxWidth="md">
        <div className={classes.cardWrapper} style={{ paddingBottom: '60px' }}>
          <Typography variant="h5" className={`${brand}-text-primary ${classes.titleText}`}>
            <FormattedMessage {...messages.billingTitle} />
          </Typography>

          <div className={classes.stripeElement}>
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                onComplete={onComplete}
                ref={ref}
                brand={brand}
                showPromoSection={showPromoSection}
                userInfo={userInfo}
              />
            </Elements>
          </div>
        </div>
      </Container>
    );
  },
);

export default BillingInformationViewStripe;
