import React, { ReactNode, Component } from 'react';

//redux
import { setBrand, setImages, setLocale, setConfig, setFlow, setBillingProvider, setLocation } from './actions';
import { connect } from 'react-redux';

//third party lib
import { brandDomain } from '../../brandConfig';
import { IImagesDict, ConfigType } from './types';
import { Helmet } from 'react-helmet';

interface Props {
  children: ReactNode;
  setBrandValue: any;
  setImagesValue: any;
  setLocaleValue: any;
  setLocationValue: any;
  setConfigValue: any;
  setFlowValue: any;
  setBillingProviderValue: any;
}
interface State {
  brand: string;
  brandName: string;
  images: IImagesDict;
  local: any;
  config: ConfigType;
  flow: 'delegatedCheckout' | 'default';
  billingProvider: 'stripe' | 'recurly' | 'stripeElements';
}

const importedImages = importAll(require.context('../../themes/', true, /\.(ico|png|jpe?g|svg)$/));

function importAll(r: __WebpackModuleApi.RequireContext) {
  const cache: IImagesDict = {};

  r.keys().forEach((key: string) => {
    cache[key] = r(key);
  });

  return cache;
}

function getImagesForBrand(images: IImagesDict, brand: string) {
  const brandImages: IImagesDict = {};
  for (const key in images) {
    if (images.hasOwnProperty(key)) {
      if (key.includes(brand)) {
        brandImages[parseKey(key)] = images[key].default ? images[key].default : images[key];
      }
    }
  }
  return brandImages;
}

function parseKey(key: string): string {
  let result = key.substr(0, key.lastIndexOf('.'));
  result = result.substring(result.lastIndexOf('/') + 1);
  return result;
}

export class BrandProvider extends Component<Props, State> {
  config = this.getDomainInfo(window.location.hostname);

  constructor(props: Props) {
    super(props);

    this.props.setBrandValue(this.state.brand, this.state.brandName);
    this.props.setImagesValue(this.state.images);
    this.props.setLocaleValue(this.state.local);
    this.props.setLocationValue(this.state.location);
    this.props.setConfigValue(this.state.config);
    this.props.setFlowValue(this.state.flow);
    this.props.setBillingProviderValue(this.state.billingProvider);
  }

  state = {
    brand: this.config.brand,
    brandName: this.config.brandName,
    images: getImagesForBrand(importedImages, this.config.brand),
    local: this.getBrowserLocale(),
    location: this.getBrowserLocation(),
    config: this.config.config,
    // The 'default' flow was the only flow until support for Stripe was added.  For backwards compatibility, set it
    // to default if it is not found in the brand config.
    flow: this.config.flow || 'default',
    billingProvider: this.config.billingProvider || 'recurly',
  };

  getBrowserLocale() {
    let language = this._getLocale();

    const index = language.indexOf('-');
    if (index !== -1) {
      language = language.substring(0, index);
    }

    return language;
  }

  getBrowserLocation() {
    let location = this._getLocale();

    const index = location.indexOf('-');
    if (index !== -1) {
      location = location.split('-')[1];
    }

    return location;
  }

  _getLocale() {
    return navigator?.userLanguage || navigator?.language || navigator?.languages?.[0];
  }

  getDomainInfo(location: string) {
    // if domain is mapped and a brand is present just use the domain for theme lookup
    let config = brandDomain[location];

    const paramValue = this.locationParamValue('brand');
    const paramNotPresent = paramValue === undefined || paramValue === null;
    const domainNotPresent = config === undefined || config === null;
    // if brand parameter is present and no doamin is mapped then use the brand for theme lookup
    if (!paramNotPresent && domainNotPresent && paramValue) {
      config = brandDomain[paramValue];

      if (config === undefined || config === null) {
        config = brandDomain.default;
      }
    }

    // if brand paramenter not present and domain is not mapped then use default
    if (domainNotPresent && paramNotPresent) {
      config = brandDomain.default;
    }

    return config;
  }

  locationParamValue(param: any): string | null {
    let value = null;
    let i, l;

    const queries = window.location.search.substring(1).split('&');

    for (i = 0, l = queries.length; i < l; i++) {
      const temp = queries[i].split('=');
      if (temp[0] === param) {
        value = temp[1];
        break;
      }
    }
    return value;
  }

  render() {
    return (
      <div>
        {this.state.images ? (
          <Helmet>
            <link rel="icon" href={this.state.images['favicon']} />
          </Helmet>
        ) : null}

        {React.Children.only(this.props.children)}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  brand: 'brand',
  images: 'images',
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setBrandValue(brand: string, brandName: string) {
      dispatch(setBrand(brand, brandName));
    },
    setImagesValue(images: IImagesDict) {
      dispatch(setImages(images));
    },
    setLocaleValue(locale: any) {
      dispatch(setLocale(locale));
    },
    setLocationValue(location: any) {
      dispatch(setLocation(location));
    },
    setConfigValue(config: object) {
      dispatch(setConfig(config));
    },
    setFlowValue(flow: string) {
      dispatch(setFlow(flow));
    },
    setBillingProviderValue(provider: string) {
      dispatch(setBillingProvider(provider));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandProvider);
