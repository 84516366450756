import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  SUBSCRIPTION_PREVIEW_ATTEMPT,
  SUBSCRIPTION_PREVIEW_SUCCESS,
  SUBSCRIPTION_PREVIEW_FAILURE,
  SUBSCRIPTION_ATTEMPT,
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_FAILURE,
  SUBSCRIPTION_CLEAR_STATE_ATTEMPT,
  SUBSCRIPTION_CLEAR_STATE,
} from './actionTypes';

import { TypedIterableIterator, SelectState } from '../../modules/helpers';
import { postDataToService } from '../../services/apiGatewayClient';

import { getPromoCodeSelector, getPromoCodesSelector } from '../PromoCode/selectors';
import { getCurrentPlanSelector, getSelectedPlanSelector } from '../PlanListPage/selectors';
import {
  getSelectedBillingInfoSelector,
  getCurrencySelector,
  getBillingInformationToken,
  getThreeDTokenSelector,
} from '../BillingInformationView/selectors';

import { getDevicesAddedSelector, getDevicesRemovedSelector } from '../ManageDevices/selectors';
import { getSelectedSubscriptionSelector } from '../SubscriptionsList/selectors';
import { Plan } from '../PlanListPage/types';
import { getBillingProviderSelector } from '../BrandProvider/selectors';
import { isV3Provider } from '../../utils/pepperUtils';

function createPreviewRequest(selectedPlan: any, selectedBillingInfo: any, currency: any) {
  let previewRequest = {
    plan_code: selectedPlan ? selectedPlan.external_plan_code : '',
    currency: currency,
    account: {
      account_code: '',
      billing_info: {
        address1: selectedBillingInfo.address1,
        address2: selectedBillingInfo.address2,
        city: selectedBillingInfo.city,
        state: selectedBillingInfo.state,
        zip: selectedBillingInfo.postal_code,
        country: selectedBillingInfo.country,
      },
    },
  };

  return previewRequest;
}

function mapSubscriptionPreview(subscriptionResponse: any) {
  return {
    currency: subscriptionResponse.currency ?? 0,
    subTotal: subscriptionResponse.subTotal ?? 0,
    tax: subscriptionResponse.tax ?? 0,
    total: subscriptionResponse.total ?? 0,
    chargeDate: subscriptionResponse.chargeDate ?? new Date().toISOString(),
  };
}

function* getRecurlyPreview(): TypedIterableIterator<any> {
  const selectedPlan: any = yield SelectState<any>(getSelectedPlanSelector);
  const selectedBillingInfo: any = yield SelectState<any>(getSelectedBillingInfoSelector);
  const currency: any = yield SelectState<any>(getCurrencySelector);

  const recurlySubscriptionPreviewRequest = createPreviewRequest(selectedPlan, selectedBillingInfo, currency);

  try {
    let subscriptionResponse = yield call(
      postDataToService,
      '/preview',
      recurlySubscriptionPreviewRequest,
      'subscriptions',
    );
    if (!subscriptionResponse) {
      let err = new Error('subscription not returned');
      yield put({
        type: SUBSCRIPTION_PREVIEW_FAILURE,
        err,
        recurlySubscriptionPreviewRequest,
      });
    } else {
      let subscriptionPreview = mapSubscriptionPreview(subscriptionResponse);
      yield put({
        type: SUBSCRIPTION_PREVIEW_SUCCESS,
        subscriptionPreview,
        subscriptionResponse,
      });
    }
  } catch (err: any) {
    let error = err;
    if (err.response && err.response.data && err.response.data.error) {
      error = new Error(err.response.data.error);
    }
    yield put({
      type: SUBSCRIPTION_PREVIEW_FAILURE,
      err: error,
      recurlySubscriptionPreviewRequest,
    });
  }
}

function createDeviceObjectFromList(devices: any) {
  let localDevices = [];
  if (devices) {
    for (let device of devices) {
      let localDevice = {
        deviceId: device.pepperDeviceId,
        externalDeviceId: device.deviceId,
        deviceProvider: device.provider,
        deviceModel: device.model,
      };
      localDevices.push(localDevice);
    }
  }
  return localDevices;
}

function createSubscriptionRequest(
  currentPlan: Plan,
  devicesAdded: any,
  devicesRemoved: any,
  selectedSubscription: any,
  selectedPlan: Plan,
  billingToken: any,
  promoCode: any,
  currency: any,
  actionResultTokenId: any,
  promoCodes: string[],
  isV3: boolean,
) {
  let currSubId = undefined;
  let pepperSubscriptionId = undefined;
  let ozTrialEndDate = undefined;

  if (selectedSubscription) {
    pepperSubscriptionId = selectedSubscription.id;
    currSubId = selectedSubscription.external_subscription_id;
    if (selectedSubscription.devices && selectedSubscription.devices.length > 0) {
      let tempDevice = selectedSubscription.devices[0];
      if (tempDevice.subscription) {
        ozTrialEndDate = tempDevice.subscription.oz_plan_expriation_date;
      }
    }
  }

  /** FIXME:  PLAY-15303: backend currently not supporting multiple promoCodes, so the 'couponCode' parameter is actually used */
  return {
    pepperPlanId: selectedPlan.id,
    pepperSubscriptionId: pepperSubscriptionId,
    devices: createDeviceObjectFromList(devicesAdded),
    devicesRemoved: createDeviceObjectFromList(devicesRemoved),
    targetPlanCode: selectedPlan.external_plan_code,
    ozTrialEndDate: ozTrialEndDate,
    currentSubscriptionUuid: currSubId,
    currency: currency,
    billingToken: billingToken,
    couponCode: promoCode ?? promoCodes[0] ?? '',
    actionResultTokenId: actionResultTokenId,
    isV3: isV3,
    // couponCodes: promoCodes,
  };
}

function* submitSubscription(): TypedIterableIterator<any> {
  const currentPlan: Plan = yield SelectState<any>(getCurrentPlanSelector);
  const selectedPlan: Plan = yield SelectState<any>(getSelectedPlanSelector);
  const devicesAdded: any = yield SelectState<any>(getDevicesAddedSelector);
  const devicesRemoved: any = yield SelectState<any>(getDevicesRemovedSelector);
  const selectedSubscription: any = yield SelectState<any>(getSelectedSubscriptionSelector);
  const billingToken: any = yield SelectState<any>(getBillingInformationToken);
  const currency: any = yield SelectState<any>(getCurrencySelector);
  const promoCode: any = yield SelectState<any>(getPromoCodeSelector);
  const promoCodes = yield SelectState<any>(getPromoCodesSelector);
  const actionResultTokenId: any = yield SelectState<any>(getThreeDTokenSelector);
  const billingProvider = yield SelectState<any>(getBillingProviderSelector);

  const isV3 = isV3Provider(billingProvider);

  let changeSubscriptionRequest = createSubscriptionRequest(
    currentPlan,
    devicesAdded,
    devicesRemoved,
    selectedSubscription,
    selectedPlan,
    billingToken,
    promoCode,
    currency,
    actionResultTokenId,
    promoCodes,
    isV3,
  );

  try {
    let subscriptionResponse = yield call(postDataToService, '/subscribe', changeSubscriptionRequest, 'subscriptions');

    if (subscriptionResponse.Payload) {
      try {
        let subscriptionPayload = JSON.parse(subscriptionResponse.Payload);
        if (subscriptionPayload.FunctionError) {
          const errorPayload = JSON.parse(subscriptionPayload.Payload);
          const err = new Error(errorPayload.errorType);
          yield put({
            type: SUBSCRIPTION_FAILURE,
            err,
            changeSubscriptionRequest,
          });
          return;
        }
      } catch (err) {
        yield put({
          type: SUBSCRIPTION_FAILURE,
          err,
          changeSubscriptionRequest,
        });
        return;
      }
    }
    yield put({ type: SUBSCRIPTION_SUCCESS, subscriptionResponse });
  } catch (err: any) {
    let error = err;
    if (err.response && err.response.data && err.response.data.error) {
      error = new Error(err.response.data.error);
    }
    yield put({ type: SUBSCRIPTION_FAILURE, error, changeSubscriptionRequest });
  }
}

function* clearStateStore() {
  yield put({ type: SUBSCRIPTION_CLEAR_STATE });
}

function* OrderSummarySaga() {
  yield all([takeLatest(SUBSCRIPTION_PREVIEW_ATTEMPT, getRecurlyPreview)]);
  yield all([takeLatest(SUBSCRIPTION_ATTEMPT, submitSubscription)]);
  yield all([takeLatest(SUBSCRIPTION_CLEAR_STATE_ATTEMPT, clearStateStore)]);
}

export default OrderSummarySaga;
