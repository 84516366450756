import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import AmexImage from '../../themes/common/images/card_amex@2x.png';
import DinersImage from '../../themes/common/images/card_diners@2x.png';
import DiscoverImage from '../../themes/common/images/card_discover@2x.png';
import JcbImage from '../../themes/common/images/card_jcb@2x.png';
import MastercardImage from '../../themes/common/images/card_mastercard@2x.png';
import VisaImage from '../../themes/common/images/card_visa@2x.png';
import MomentumImage from '../../themes/momentum/images/Momentum_Favicon96x96.png';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Typography } from '@material-ui/core';
import messages from './messages';
import history from '../../history';
import { Dictionary } from '../../modules/helpers';
import { setPrevPathName } from '../PathName/actions';
import { useDispatch, useSelector } from 'react-redux';
import { clearBillingInfoState, createBillingInfoUpdateDelegateAttempt } from './actions';
import {
  getBillingInfoSessionUrlSelector,
  getBillingInfoSessionUrlFailureSelector,
  getBillingInfoSessionUrlAttemptSelector,
} from './selectors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getFlowSelector } from '../BrandProvider/selectors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    '& > *': {
      width: '100%',
      height: theme.spacing(12),
    },
  },
}));

interface Props {
  brand: string;
  paymentInfo: any;
  subscriptions: any;
  paymentInfoError: any;
  intl: any;
}

const CardTypes: Dictionary<any> = {
  mastercard: MastercardImage,
  visa: VisaImage,
  'american express': AmexImage,
  american_express: AmexImage,
  discover: DiscoverImage,
  'diners club': DinersImage,
  diners_club: DinersImage,
  jcb: JcbImage,
  apple: MomentumImage,
};

const PaymentInfoControl = (props: Props) => {
  const classes = useStyles();
  const { brand, paymentInfo, subscriptions, paymentInfoError } = props;
  const dispatch = useDispatch();

  const getCardTypeMedia = (cardType: string) => {
    cardType = cardType?.toLowerCase() || 'visa';
    let imageValue = null;

    imageValue = CardTypes[cardType];

    if (!imageValue) {
      return null;
    }

    const { formatMessage } = props.intl;
    return (
      <img style={{ width: '44px', height: '44px' }} src={imageValue} alt={formatMessage(messages.cardTypeAltText)} />
    );
  };

  const containsAppleSubscription = () => {
    let appleSubscriptionExists = subscriptions.find((subscription: any) => {
      return subscription.plan_config?.provider?.toLowerCase() === 'apple';
    });

    return !!appleSubscriptionExists;
  };

  const flow = useSelector(getFlowSelector);
  const updateBillingInfo = () => {
    if (flow === 'delegatedCheckout') {
      dispatch(createBillingInfoUpdateDelegateAttempt());
    } else {
      dispatch(setPrevPathName('/payment'));
      history.push('/billing');
    }
  };

  const updateBillingInfoSessionUrlAttempt = useSelector(getBillingInfoSessionUrlAttemptSelector);

  const updateBillingInfoSessionUrl = useSelector(getBillingInfoSessionUrlSelector);
  useEffect(() => {
    if (updateBillingInfoSessionUrl) {
      const url = updateBillingInfoSessionUrl;
      dispatch(clearBillingInfoState());
      window.location.replace(url);
    }
  }, [updateBillingInfoSessionUrl, dispatch]);

  const updateBillingInfoSessionUrlFailure = useSelector(getBillingInfoSessionUrlFailureSelector);
  useEffect(() => {
    if (updateBillingInfoSessionUrlFailure) {
      dispatch(clearBillingInfoState());
      history.push('/error');
    }
  });

  const renderUserPayment = () => {
    const hasAppleSub = containsAppleSubscription();
    if (paymentInfo && !hasAppleSub && !paymentInfoError) {
      if (Object.keys(paymentInfo).length === 0) {
        return (
          <Typography variant="h6" align="center" style={{ margin: 12 }}>
            <FormattedMessage {...messages.noCreditCard} />
          </Typography>
        );
      }
      return getPaperCardData(paymentInfo.card_type, `ending in ****${paymentInfo.last_four}`, true);
    } else if (hasAppleSub) {
      return getPaperCardData(
        <FormattedMessage {...messages.applePayment} />,
        <FormattedMessage {...messages.applePaymentMessage} />,
        false,
      );
    } else {
      return (
        <Typography variant="body1" align="center" style={{ margin: 10 }}>
          <FormattedMessage {...messages.networkError} />
        </Typography>
      );
    }
  };

  const getPaperCardData = (cardType: any, message: any, hasButton: boolean) => {
    return (
      <Paper variant="outlined" square>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 'inherit',
          }}>
          <div
            id="img-card-and-text"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 'inherit',
              marginLeft: 15,
            }}>
            <div>{paymentInfo.card_type && getCardTypeMedia(paymentInfo.card_type)}</div>
            <div
              style={{
                marginLeft: 10,
                fontWeight: 'bolder',
                fontSize: 'larger',
              }}>
              <span
                style={{
                  textTransform: 'capitalize',
                }}>
                {cardType}
              </span>{' '}
              {message}
            </div>
          </div>
          <div
            style={{
              marginRight: 15,
            }}>
            {hasButton ? (
              <Button
                id="edit-payment-btn"
                onClick={updateBillingInfo}
                className={`${brand}-btn-text`}
                style={{
                  textTransform: 'none',
                }}>
                <FormattedMessage {...messages.changeText} />
              </Button>
            ) : null}
          </div>
        </div>
      </Paper>
    );
  };
  return (
    <div className={classes.root}>
      {updateBillingInfoSessionUrlAttempt ? (
        <div className="progress-wrapper-center">
          <CircularProgress size={55} className={`${brand}-button-progress`} />
        </div>
      ) : (
        renderUserPayment()
      )}
    </div>
  );
};

export default injectIntl(PaymentInfoControl);
