import React from 'react';

//material ui
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';

//images
import AmexImage from '../../themes/common/images/card_amex@2x.png';
import DinersImage from '../../themes/common/images/card_diners@2x.png';
import DiscoverImage from '../../themes/common/images/card_discover@2x.png';
import JcbImage from '../../themes/common/images/card_jcb@2x.png';
import MastercardImage from '../../themes/common/images/card_mastercard@2x.png';
import VisaImage from '../../themes/common/images/card_visa@2x.png';

//third party lib
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';
import orderBy from 'lodash/orderBy';

//components
import DateFormatter from '../../components/Formatters/DateFormatter';

interface Props {
  accountHistory: any;
  containsAppleSub: boolean;
  brand: string;
  intl: any;
}
const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: 10,
  },
  body: {
    fontSize: 14,
    padding: 10,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const AccountHistoryTable = (props: Props) => {
  const classes = useStyles();
  const { containsAppleSub, accountHistory } = props;

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const getCardTypeMedia = (cardType: string) => {
    cardType = cardType.toLowerCase();

    let imageValue = null;
    let abbrCardType = cardType;

    if (cardType === 'mastercard') {
      imageValue = MastercardImage;
      abbrCardType = 'MC';
    } else if (cardType === 'visa') {
      imageValue = VisaImage;
      abbrCardType = 'VISA';
    } else if (cardType === 'american express' || cardType === 'american_express') {
      imageValue = AmexImage;
      abbrCardType = 'AMEX';
    } else if (cardType === 'discover') {
      imageValue = DiscoverImage;
      abbrCardType = 'DISC';
    } else if (cardType === 'diners club' || cardType === 'diners_club') {
      imageValue = DinersImage;
      abbrCardType = 'DC';
    } else if (cardType === 'jcb') {
      imageValue = JcbImage;
      abbrCardType = 'JCB';
    }

    if (!imageValue) {
      return abbrCardType;
    }
    const { formatMessage } = props.intl;
    return (
      <img style={{ width: '25px', height: '25px' }} src={imageValue} alt={formatMessage(messages.cardTypeAltText)} />
    );
  };

  const getDateField = (payment: any) => {
    if (!payment.invoiceDate) {
      return '';
    }
    const date = new Date(payment.invoiceDate);
    return <DateFormatter date={date} />;
  };

  const getStatusField = (payment: any) => {
    if (!payment.status) {
      return '';
    } else if (payment.invoiceTotal && parseInt(payment.invoiceTotal, 10) === 0) {
      return 'N/A';
    } else if (payment.invoiceTotal && parseInt(payment.invoiceTotal, 10) < 0) {
      return props.intl.formatMessage(messages.statusRefund);
    }

    switch (payment.status) {
      case 'collected':
      case 'paid':
        return props.intl.formatMessage(messages.statusPaid);
      case 'pending':
        return <span className="text-warning">{props.intl.formatMessage(messages.statusPending)}</span>;
      case 'failed':
        return <span className="text-danger">{props.intl.formatMessage(messages.statusFailed)}</span>;
      case 'past_due':
        return <span className="text-danger">{props.intl.formatMessage(messages.statusPastDue)}</span>;
      default:
        return '';
    }
  };

  const getAmountField = (payment: any) => {
    if (typeof payment.invoiceTotal === 'undefined' || !payment.currency) {
      return '';
    }
    const formattedCurrency = props.intl.formatNumber(payment.invoiceTotal, {
      style: 'currency',
      currency: payment.currency,
      currencyDisplay: 'symbol',
    });
    return (
      <div>
        <span>{formattedCurrency}</span> <span>{payment.currency}</span>
      </div>
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPaymentMethodField = (payment: any) => {
    if (
      !payment.cardType ||
      payment.cardType === 'Unknown' ||
      !payment.lastFour ||
      typeof payment.lastFour !== 'string'
    ) {
      return 'N/A';
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {getCardTypeMedia(payment.cardType)}
        <span className="pl-1">****{payment.lastFour}</span>
      </div>
    );
  };

  const getInvoiceNumField = (payment: any) => {
    if (!payment.invoiceId) {
      return '';
    }
    return '#' + payment.invoiceId;
  };

  const renderAcctHistoryList = () => {
    const sortedAcctHistory = orderBy(
      accountHistory,
      [
        (v: any) => {
          return v.invoiceDate ? new Date(v.invoiceDate) : false;
        },
      ],
      ['desc', 'asc'],
    );

    return sortedAcctHistory
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((payment: any, i: number) => (
        <StyledTableRow key={i}>
          <StyledTableCell align="left">{getDateField(payment)}</StyledTableCell>
          <StyledTableCell align="left">{getStatusField(payment)}</StyledTableCell>
          <StyledTableCell align="left">{getAmountField(payment)}</StyledTableCell>
          <StyledTableCell align="left">{getPaymentMethodField(payment)}</StyledTableCell>
          <StyledTableCell align="left">{getInvoiceNumField(payment)}</StyledTableCell>
        </StyledTableRow>
      ));
  };

  return (
    <div style={{ margin: 25 }}>
      <div>
        {containsAppleSub && (
          <Alert severity="info" style={{ marginBottom: 25 }}>
            <AlertTitle>
              <FormattedMessage {...messages.appleSubscriptions} />
            </AlertTitle>
          </Alert>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: 'bolder' }}>
                {props.intl.formatMessage(messages.columnDate)}
              </TableCell>
              <TableCell align="left" style={{ fontWeight: 'bolder' }}>
                {props.intl.formatMessage(messages.columnStatus)}
              </TableCell>
              <TableCell align="left" style={{ fontWeight: 'bolder' }}>
                {props.intl.formatMessage(messages.columnAmount)}
              </TableCell>
              <TableCell align="left" style={{ fontWeight: 'bolder' }}>
                {props.intl.formatMessage(messages.columnPaymentMethod)}
              </TableCell>
              <TableCell align="left" style={{ fontWeight: 'bolder' }}>
                {props.intl.formatMessage(messages.columnInvoiceNum)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderAcctHistoryList()}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={accountHistory.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default injectIntl(AccountHistoryTable);
