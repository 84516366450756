import React from 'react';

//material ui
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';

//redux
import { useSelector } from 'react-redux';
import { getBrandImagesSelector } from '../BrandProvider/selectors';
import { getCurrencySelector } from '../BillingInformationView/selectors';
import { getSubscriptionResponseSelector } from '../OrderSummary/selectors';
import { getPromoCodeValidationResSelector } from '../PromoCode/selectors';
import { getSelectedPlanSelector, getCurrentPlanSelector } from '../PlanListPage/selectors';

//third party lib
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';

//utils
import { isDate } from '../../utils/pepperUtils';

//components
import CurrencyFormatter from '../../components/Formatters/CurrencyFormatter';
import DateFormatter from '../../components/Formatters/DateFormatter';
import { getInvoiceDetailsResponseSelector } from '../CheckoutSuccess/selectors';

interface Props {
  open: boolean;
  handleClose: Function;
  brand: string;
  tax?: any;
  intl: any;
}

const isInTrial = (startDate: any, endDate: any) => {
  // Check if either date is null or invalid
  if (!startDate || !endDate || startDate['$']?.nil === 'nil' || endDate['$']?.nil === 'nil') {
    return false; // Cannot be in trial with invalid dates
  }

  const dStart = startDate.split('-');
  const dEnd = endDate.split('-');

  dStart[2] = dStart[2].replace(/(^\d+)(.+$)/i, '$1');
  dEnd[2] = dEnd[2].replace(/(^\d+)(.+$)/i, '$1');

  const from = new Date(dStart[0], parseInt(dStart[1]) - 1, dStart[2]); // -1 because months are from 0 to 11
  const to = new Date(dEnd[0], parseInt(dEnd[1]) - 1, dEnd[2]);
  const now = new Date(Date.now());

  return now > from && now < to;
};

const PlanSubscriptionSuccessModal = (props: Props) => {
  const { open, brand } = props;
  const { formatMessage } = props.intl;
  const images = useSelector(getBrandImagesSelector);
  const newPlan = useSelector(getSelectedPlanSelector);
  const currency = useSelector(getCurrencySelector);
  const currentPlan = useSelector(getCurrentPlanSelector);
  const subscriptionResponse = useSelector(getSubscriptionResponseSelector);
  const invoiceDetails = useSelector(getInvoiceDetailsResponseSelector);
  const promoCode = useSelector(getPromoCodeValidationResSelector);

  const handleClose = () => {
    props.handleClose();
  };

  const getRecurringChargeAmount = () => {
    let taxAmount = 0;
    let amount = newPlan?.amountInCents?.[currency]?._ ?? '0';

    if (brand && brand === 'otis') {
      if (subscriptionResponse && subscriptionResponse.tax_in_cents) {
        if (props.tax) {
          taxAmount = parseFloat(props.tax);
        } else {
          taxAmount = parseFloat(subscriptionResponse.tax_in_cents._);
        }
      }
    }
    return parseFloat(amount) + taxAmount;
  };

  const getRecurringChargeContent = () => {
    const { formatMessage } = props.intl;

    return (
      <div>
        <CurrencyFormatter value={getRecurringChargeAmount()} />
        <span>{formatMessage(messages['billingCycle' + newPlan?.billing_cycle])}</span>
      </div>
    );
  };

  const isSubscriptionResponseExist = () => {
    return (
      subscriptionResponse &&
      subscriptionResponse !== undefined &&
      (subscriptionResponse.trial_started_at?._ || subscriptionResponse.trial_started_at) &&
      (subscriptionResponse.trial_ends_at?._ || subscriptionResponse.trial_ends_at)
    );
  };

  const isSubExistAndInTrial = () => {
    if (isSubscriptionResponseExist()) {
      if (
        isInTrial(
          subscriptionResponse.trial_started_at?._ || subscriptionResponse.trial_started_at,
          subscriptionResponse.trial_ends_at?._ || subscriptionResponse.trial_ends_at,
        )
      ) {
        return true;
      }
    }

    return false;
  };

  const getNextChargeDate = (): string | number => {
    if (subscriptionResponse) {
      const currentPeriodEndsDate = new Date(
        subscriptionResponse.current_period_ends_at?._ || subscriptionResponse.current_period_ends_at,
      );
      if (isDate(currentPeriodEndsDate)) {
        return currentPeriodEndsDate.getTime();
      }
      return '';
    } else if (invoiceDetails) {
      const dueDate = new Date(invoiceDetails.due_date);
      if (isDate(dueDate)) {
        return dueDate.getTime();
      }
      return '';
    }
    return '';
  };

  const hasPromoDescription = () => {
    if (promoCode && promoCode.description) {
      return true;
    }

    return false;
  };

  const getChargedTodayAmount = () => {
    if (isSubscriptionResponseExist()) {
      if (
        isInTrial(
          subscriptionResponse.trial_started_at?._ || subscriptionResponse.trial_started_at,
          subscriptionResponse.trial_ends_at?._ || subscriptionResponse.trial_ends_at,
        )
      ) {
        return 0.0;
      }
    }

    if (currentPlan && newPlan && Number(newPlan.billing_order) < Number(currentPlan.billing_order)) {
      return 0;
    }

    let curPlanAmt = 0;
    if (currentPlan?.amountInCents) {
      const amt = currentPlan.amountInCents[currency];
      curPlanAmt = amt?._ ? parseInt(amt._) : curPlanAmt;
    }
    const nextPlanAmt = parseFloat(newPlan?.amountInCents?.[currency]?._ || '0');
    let taxAmount = 0;

    let amountChargedToday: string = (nextPlanAmt - curPlanAmt + taxAmount).toString();
    if (parseFloat(amountChargedToday) < 0) {
      return 0;
    }
    return amountChargedToday;
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleClose}>
        <IconButton
          id="exit-model"
          aria-label="close"
          style={{ position: 'absolute', top: 0, right: 0 }}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <DialogTitle id="subs-success-modal-title" style={{ paddingBottom: 0 }}>
          <img
            src={images['subscription-success-icon']}
            alt="Subscription Success"
            className={`${brand}-confirm-modal-icon`}
          />
        </DialogTitle>
        <DialogTitle id="subs-success-modal-title" style={{ fontWeight: 'bold', paddingTop: 0 }}>
          <FormattedMessage {...messages.successStatement} />
        </DialogTitle>
        <Divider style={{ margin: 25 }} />
        <DialogContent>
          <div
            style={{
              marginBottom: 0,
            }}
            id="subs-success-modal-description">
            <Typography
              variant="overline"
              style={{
                fontWeight: 'bolder',
                fontSize: '1rem',
                marginRight: 10,
              }}>
              <FormattedMessage {...messages.planLabel} />
            </Typography>
            <Typography variant="subtitle1">{newPlan?.name} Plan</Typography>
          </div>
          <div
            style={{
              marginBottom: 0,
            }}
            id="subs-success-modal-description">
            <Typography
              variant="overline"
              style={{
                fontWeight: 'bolder',
                fontSize: '1rem',
                marginRight: 10,
              }}
              align="right">
              <FormattedMessage {...messages.chargeLabel} />
            </Typography>
            <Typography variant="subtitle1">{getRecurringChargeContent()}</Typography>
          </div>
          <div
            style={{
              marginBottom: 0,
            }}
            id="subs-success-modal-description">
            <Typography
              variant="overline"
              style={{
                fontWeight: 'bolder',
                fontSize: '1rem',
                marginRight: 10,
              }}
              align="right">
              <FormattedMessage {...messages.appliedPromoLabel} />
            </Typography>
            <Typography variant="subtitle1">
              {isSubExistAndInTrial() && hasPromoDescription()
                ? 'Free trial, ' + promoCode.description
                : isSubExistAndInTrial()
                ? '30-day free trial'
                : hasPromoDescription()
                ? promoCode.description
                : formatMessage(messages.noPromotionMessage)}
            </Typography>
          </div>
          <div
            style={{
              marginBottom: 0,
            }}
            id="subs-success-modal-description">
            <Typography
              variant="overline"
              style={{
                fontWeight: 'bolder',
                fontSize: '1rem',
                marginRight: 10,
              }}
              align="right">
              <FormattedMessage {...messages.chargedTodayLabel} />
            </Typography>
            {currentPlan ? (
              <Typography variant="subtitle1">
                <CurrencyFormatter value={getChargedTodayAmount()} />
              </Typography>
            ) : (
              <Typography variant="subtitle1">
                <CurrencyFormatter value={0} />
              </Typography>
            )}
          </div>
          <div
            style={{
              marginBottom: 0,
            }}
            id="subs-success-modal-description">
            {!!getNextChargeDate() ? (
              <>
                <Typography
                  variant="overline"
                  style={{
                    fontWeight: 'bolder',
                    fontSize: '1rem',
                    marginRight: 10,
                  }}
                  align="right">
                  <FormattedMessage {...messages.nextChargeLabel} />
                </Typography>
                <Typography variant="subtitle1">
                  <DateFormatter date={getNextChargeDate()} />
                </Typography>
              </>
            ) : (
              <></>
            )}
          </div>
        </DialogContent>
        <DialogActions id="alert-dialog-actions"></DialogActions>
      </Dialog>
    </div>
  );
};

export default injectIntl(PlanSubscriptionSuccessModal);
