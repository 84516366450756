import { createSelector } from 'reselect';

import { AppState } from '../../store/rootReducer';

const getAttemptingSubscriptionPlans = (state: AppState) => state.subscriptionList.attempting;
const getSubscriptionPlans = (state: AppState) => state.subscriptionList.subscriptionPlans;

export const getCurrentSubscription = createSelector(getSubscriptionPlans, subscriptionPlans => {
  // If there is at least one subscription, determine which subscription will be used to change plan.  If there are
  // no subscriptions, then something is wrong.  There should at least be a "no plan" subscription.
  if (subscriptionPlans.length > 0) {
    // If there is a paid plan then that is the one to use.
    const paidPlan = subscriptionPlans.find(
      (subscription: any) => !subscription.plan_config.default && subscription.plan_config.visible,
    );

    // Return the paid plan if there is one.  Otherwise, assume there is only one plan, and it is the default plan,
    // and return it.
    if (paidPlan) {
      return paidPlan;
    } else {
      return subscriptionPlans.find((subscription: any) => subscription.plan_config.default);
    }
  } else return undefined;
});

const getIsMigrationRequired = (state: AppState) => state.subscriptionList.isMigrationRequired;

const getMigrationAttempt = (state: AppState) => state.subscriptionList.migrationAttempting;

const getDeviceModelsAttempt = (state: AppState) => state.subscriptionList.modelsAttempting;

const getMigrationComplete = (state: AppState) => state.subscriptionList.migrationComplete;

const getMigrationError = (state: AppState) => state.subscriptionList.migrationError;

const getWasMigrated = (state: AppState) => state.subscriptionList.wasMigrated;

const getMigratedSubscription = (state: AppState) => state.subscriptionList.migratedSubscription;

const getCheckingMigration = (state: AppState) => state.subscriptionList.checkingMigration;

const getDeviceModels = (state: AppState) => state.subscriptionList.deviceModels;

const getLastDeviceModelsFetch = (state: AppState) => state.subscriptionList.lastDeviceModelsFetch;

export const getLastDeviceModelsFetchSelector = createSelector(getLastDeviceModelsFetch, lastFetch => lastFetch);

export const getAttemptingSubscriptionPlansSelector = createSelector(
  getAttemptingSubscriptionPlans,
  attempting => attempting === 'pending',
);

export const getSubscriptionsListLoadedSelector = createSelector(
  getAttemptingSubscriptionPlans,
  attempting => attempting === 'fulfilled',
);

export const getSubscriptionPlansSelector = createSelector(
  getSubscriptionPlans,
  subscriptionPlans => subscriptionPlans,
);

export const getSelectedSubscriptionSelector = createSelector(
  getCurrentSubscription,
  selectedSubscription => selectedSubscription,
);

export const getIsMigrationRequiredSelector = createSelector(
  getIsMigrationRequired,
  isMigrationRequired => isMigrationRequired,
);

export const getDeviceModelsSelector = createSelector(getDeviceModels, deviceModels => deviceModels);

export const getMigrationAttemptSelector = createSelector(getMigrationAttempt, attempting => attempting);

export const getDeviceModelsAttemptSelector = createSelector(getDeviceModelsAttempt, attempting => attempting);

export const getMigrationCompleteSelector = createSelector(getMigrationComplete, complete => complete);

export const getMigrationErrorSelector = createSelector(getMigrationError, err => err);

export const getWasMigratedSelector = createSelector(getWasMigrated, wasMigrated => wasMigrated);

export const getMigratedSubscriptionSelector = createSelector(getMigratedSubscription, migratedSub => migratedSub);

export const getCheckingMigrationSelector = createSelector(getCheckingMigration, checking => checking);
