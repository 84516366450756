import * as types from './actionTypes';

export const storeBillingInfoStripeAttempt = (payload: any) => ({
  type: types.STORE_BILLING_INFO_STRIPE_ATTEMPT,
  payload,
});

export const storeBillingInfoStripeSuccess = (payload: any) => ({
  type: types.STORE_BILLING_INFO_STRIPE_SUCCESS,
  payload,
});

export const storeBillingInfoStripeFailure = (error: any) => ({
  type: types.STORE_BILLING_INFO_STRIPE_FAILURE,
  error,
});

export const verifyBillingInfoStripeAttempt = (payload: any) => ({
  type: types.VERIFY_BILLING_INFO_STRIPE_ATTEMPT,
  payload,
});

export const verifyBillingInfoStripeSuccess = (payload: any) => ({
  type: types.VERIFY_BILLING_INFO_STRIPE_SUCCESS,
  payload,
});

export const verifyBillingInfoStripeFailure = (error: any) => ({
  type: types.VERIFY_BILLING_INFO_STRIPE_FAILURE,
  error,
});

export const fetchClientSecretAttempt = () => ({
  type: types.FETCH_CLIENT_SECRET_ATTEMPT,
});

export const fetchClientSecretSuccess = (clientSecret: string) => ({
  type: types.FETCH_CLIENT_SECRET_SUCCESS,
  payload: clientSecret,
});

export const fetchClientSecretFailure = (error: any) => ({
  type: types.FETCH_CLIENT_SECRET_FAILURE,
  error,
});

export const fetchBillingInfoStripeAttempt = (paymentMethodId: string, onComplete: (billingInfo: any) => void) => ({
  type: types.FETCH_BILLING_INFO_STRIPE_ATTEMPT,
  payload: paymentMethodId,
  onComplete,
});

export const fetchBillingInfoStripeSuccess = (billingInfo: any) => ({
  type: types.FETCH_BILLING_INFO_STRIPE_SUCCESS,
  payload: billingInfo,
});

export const fetchBillingInfoStripeFailure = (error: any) => ({
  type: types.FETCH_BILLING_INFO_STRIPE_FAILURE,
  error,
});
