import React, { useEffect, useState } from 'react';

//material ui
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';

//controls
import PaymentDetailsHeader from './PaymentDetailsHeader';
import { getBrandErrorString } from '../../components/BrandMessage';
import PaymentDetailsRecurly from './PaymentDetailsRecurly';
import PaymentDetailsStripe from './PaymentDetailsStripe';
import PaymentDetailsStripeElements from './PaymentDetailsStripeElements';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { getBillingProviderSelector, getBrandNameSelector } from '../BrandProvider/selectors';
import { getPrevPathSelector } from '../PathName/selectors';
import { getSelectedPlanSelector } from '../PlanListPage/selectors';
import {
  getCurrencySelector,
  getSavingBillingInfoSelector,
  getSelectedBillingInfoSelector,
} from '../BillingInformationView/selectors';
import { IBillingUserInfo } from './types';
import { loadingBillingInfoView } from '../BillingInformationView/actions';
import { getProfileSelector } from '../LoginPage/selectors';

// Analytics
import { AnalyticsEvent } from '../../utils/analytics/events';
import { logEvent } from '../../utils/analytics/analyticsLogger';

//third party
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import history from '../../history';

interface Props {
  intl: any;
  location: any;
}

const PaymentDetails = (props: Props) => {
  const selectedBillingInfo = useSelector(getSelectedBillingInfoSelector);
  const savingBillingInfo = useSelector(getSavingBillingInfoSelector);
  const selectedPlan = useSelector(getSelectedPlanSelector);
  const currency = useSelector(getCurrencySelector);
  const lastPath = useSelector(getPrevPathSelector);
  const brand = useSelector(getBrandNameSelector);
  const provider = useSelector(getBillingProviderSelector);
  const userProfile = useSelector(getProfileSelector);

  const [errorMapping, setErrorMapping] = useState<any>(null);
  const errorProps = { intl: props.intl, brand: brand };

  const dispatch = useDispatch();

  //states
  const [userInfo, setUserInfo] = useState<IBillingUserInfo>({
    first_name: '',
    last_name: '',
    address1: '',
    address2: '',
    city: '',
    state: 'select',
    postal_code: '',
    country: 'US',
    isCountryUs: true,
    token: '',
    cardNumberLast4: '',
  });

  const handleInputChange = (e: any, id: string | undefined = undefined) => {
    if (e) {
      const target = e.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;

      if (id) {
        setUserInfo(prevState => ({ ...prevState, [id]: value }));
      } else {
        setUserInfo(prevState => ({ ...prevState, [target.id]: value }));
      }
    }
  };

  const handleInputChangeCountry = (e: any, id: string | undefined = undefined) => {
    handleInputChange(e, id);

    setUserInfo(prevState => ({
      ...prevState,
      isCountryUs: e.target.value === 'US',
      postal_code: '',
      state: '',
    }));
  };

  useEffect(() => {
    if (selectedBillingInfo) {
      setUserInfo({
        first_name: selectedBillingInfo.first_name,
        last_name: selectedBillingInfo.last_name,
        address1: selectedBillingInfo.address1,
        address2: selectedBillingInfo.address2,
        city: selectedBillingInfo.city,
        state: selectedBillingInfo.state,
        postal_code: selectedBillingInfo.postal_code,
        country: selectedBillingInfo.country || 'US',
        isCountryUs: true,
        token: '',
        cardNumberLast4: '',
      });
    } else {
      dispatch(loadingBillingInfoView());
    }
  }, [selectedBillingInfo, dispatch]);

  useEffect(() => {
    if (props.location && props.location.search !== '') {
      const query = queryString.parse(props.location.search);
      if (query.error_code) {
        setErrorMapping(query.error_code);
      }
      history.push('/billing'); // remove query params
    }
  }, [props.location]);

  const updateBillingInfo = (newBillingInfo: IBillingUserInfo) => {
    setUserInfo(newBillingInfo);
  };
  const updateErrorMapping = (newErrorMapping: any) => {
    setErrorMapping(newErrorMapping);
  };

  const billingDetailsByProvider = () => {
    // Log landing on payment details page
    logEvent(AnalyticsEvent.ACCTDASH_PAYMENT, userProfile);

    switch (provider) {
      case 'stripe':
        return <PaymentDetailsStripe brand={brand} lastPath={lastPath} />;
      case 'stripeElements':
        return <PaymentDetailsStripeElements brand={brand} lastPath={lastPath} />;
      case 'recurly':
      default:
        return (
          <PaymentDetailsRecurly
            brand={brand}
            lastPath={lastPath}
            userInfo={userInfo}
            updateErrorMapping={updateErrorMapping}
            updateBillingInfo={updateBillingInfo}
            handleInputChange={handleInputChange}
            handleInputChangeCountry={handleInputChangeCountry}
          />
        );
    }
  };

  return (
    <div>
      {(lastPath !== '/payment' && !selectedPlan) || savingBillingInfo ? (
        <div className="progress-wrapper-center">
          <CircularProgress size={55} className={`${brand}-button-progress`} />
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          {lastPath !== '/payment' ? (
            <PaymentDetailsHeader selectedPlan={selectedPlan} currency={currency} brand={brand}></PaymentDetailsHeader>
          ) : null}
          <div>
            {errorMapping && errorMapping !== '' ? (
              <Alert variant="filled" severity="error" style={{ borderRadius: 0, justifyContent: 'center' }}>
                <AlertTitle>{getBrandErrorString(errorMapping, errorProps)}</AlertTitle>
              </Alert>
            ) : null}
            {billingDetailsByProvider()}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(injectIntl(PaymentDetails));
