import { createSelector } from 'reselect';

const getStripeState = (state: any) => state.stripe;

export const getStripeLoadingSelector = createSelector([getStripeState], state => state.loading);

export const getStripeBillingInfoSelector = createSelector([getStripeState], state => state.billingInfo);

export const getStripeErrorSelector = createSelector([getStripeState], state => state.error);

export const getClientSecretSelector = createSelector([getStripeState], state => state.clientSecret);

export const getStripeLoadingClientSecretSelector = createSelector(
  [getStripeState],
  state => state.loadingClientSecret,
);

export const getStripeLoadingBillingInfoSelector = createSelector([getStripeState], state => state.loadingBillingInfo);

export const getStripeBillingInfoFromRecurlySelector = createSelector(
  [getStripeState],
  state => state.billingInfoFromRecurly,
);
