import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setBillingProvider } from '../BrandProvider/actions';
import { STRIPE_ELEMENTS } from '../../constants/providerNames';
import history from '../../history';
import { getBillingProviderSelector } from '../BrandProvider/selectors';

interface Props {
  setBillingProviderValue: (provider: string) => void;
  currentProvider: string;
}

const StripeElementsSetup: React.FC<Props> = ({ setBillingProviderValue, currentProvider }) => {
  useEffect(() => {
    setBillingProviderValue(STRIPE_ELEMENTS);
  }, [setBillingProviderValue]);

  useEffect(() => {
    if (currentProvider === STRIPE_ELEMENTS) {
      history.push('/subscriptions');
    }
  }, [currentProvider]);

  return (
    <div>
      Setting up Stripe Elements...
      <br />
      Current Provider: {currentProvider}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentProvider: getBillingProviderSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setBillingProviderValue: (provider: string) => dispatch(setBillingProvider(provider)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StripeElementsSetup);
